import React from "react"

export const Mailto = () => {
  return (
    <a href="mailto:berlintechnonarrative@gmail.com">
      <div
        className={
          "text-justify font-bold bg-red-600 hover:bg-red-800 transition duration-75 ease-in-out text-white justify-center items-center p-5"
        }
      >
        <p className={"text-center md:text-2xl"}>
          berlintechnonarrative@gmail.com
        </p>
      </div>
    </a>
  )
}
